<template>
  <div id="QA_qrcode">
    <h1 class="title has-text-centered">
      Scan QR Code
    </h1>

    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <Notification
      v-if="unsupportedAppleMobileBrowser"
      type="warning"
    >
      You may experience issues scanning QR codes with your current browser. We suggest Safari on iOS.
    </Notification>

    <template v-if="scanAllowed">
      <qrcode-drop-zone
        v-if="environment == 'development'"
        @decode="onDecode"
        @init="logErrors"
      >
        <qrcode-stream
          @decode="onDecode"
          @init="onInit"
        >
          <Loader
            v-if="loading || submitting"
          />
        </qrcode-stream>
      </qrcode-drop-zone>
      <qrcode-stream
        v-else
        @decode="onDecode"
        @init="onInit"
      >
        <Loader
          v-if="loading || submitting"
        />
      </qrcode-stream>
      <!--
      <qrcode-capture
        v-if="noStreamApiSupport"
        @decode="onDecode"
      />
      -->
    </template>
    <button 
      class="button"
      style="margin-top: 20px;"
      @click="back()">
      cancel
    </button>

    <BaseModal
      v-if="scanAllowed && showInstructions"
      title="Prepare to Scan"
      @close="closeInstructions"
    >
      <template v-slot:default>
        <div>
          <p class="is-size-4 has-text-weight-bold has-text-centered">{{ currentDeal.business.name }}</p>
          <p class="is-size-4 has-text-centered">{{ currentDeal.description }}</p>
          <p class="is-italic" style="margin-top: 1.5rem;">Upon completing your purchase, show this screen to the merchant. You will then be shown a QR code to scan to earn your reward.</p>
        </div>
      </template>
      <template v-slot:footer>
        <button
          class="button is-link"
          @click="closeInstructions"
        >
          OK
        </button>
      </template>
    </BaseModal>

  </div>
</template>

<script>
import url from "@/service/url.js"
import DealScanMixin from "@/mixins/DealScanMixin.js"
import {AuthError, NetworkError} from "@/errors"
import Notification from "@/components/Notification.vue"
import BaseModal from "@/components/modal/BaseModal.vue"
import Loader from "@/components/Loader.vue"
import { QrcodeStream, QrcodeDropZone } from 'vue-qrcode-reader'

export default {
  props: {
    orgSlug: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    locationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showInstructions: true,
      noStreamApiSupport: false,
      loading: true,
      submitting: false,
      error: null
    };
  },
  mixins: [DealScanMixin],
  components: {
    Notification,
    BaseModal,
    Loader,
    QrcodeStream,
    QrcodeDropZone
  },
  computed: {
    environment: function() {
      return url.get_env()
    },
    loggedIn: function() {
      return this.$store.getters.isLoggedIn
    },
    currentUser: function() {
      return this.$store.getters.currentUser
    },
    isAdmin: function() {
      return this.$store.getters.isAdmin
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness
    },
    canEdit: function() {
      return ((this.$store.getters.canEdit || this.isAdmin)
        && typeof this.currentDeal.slug !== 'undefined'
        && this.currentDeal.business.slug === this.currentBusiness.slug
      ) ? true : false;
    },
    currentDealLocation: function() {
      return this.currentDeal.business.locations.find(l => l.id == this.locationId)
    },
    isPhysicalLocation: function() {
      if (!this.currentDealLocation) {
        return null
      }
      return this.currentDealLocation.is_physical_location
    },
    unsupportedAppleMobileBrowser: function() {
      const ua = window.navigator.userAgent
      return (
        !window.NativeScript
        && ua.match(/iP(ad|od|hone)/i)
        && ua.match(/WebKit/i)
        // Chrome, Firefox, Opera, etc. for iOS
        && ua.match(/CriOS|FxiOS|OPiOS|mercury/i)
      ) ? true : false
    }
  },
  mounted() {
    this.updateData()
    if (!this.scanAllowed) {
      this.error = this.reasonScanNotAllowed
    }
  },
  methods: {
    updateData() {
      if (!this.currentDeal || this.currentDeal.slug !== this.slug) {
        this.$store.dispatch("getDeal", this.slug)
          .then(() => {
            if (!this.currentDeal) {
              this.error = "Deal not found!"
            } else if (!this.scanAllowed) {
              this.error = this.reasonScanNotAllowed
            }
          })
      }
    },
    closeInstructions() {
      this.showInstructions = false
    },
    back() {
      this.$router.go(-1)
    },
    onDecode(result) {
      this.submitting = true;

      const params = {
        user_slug: this.currentUser.slug,
        deal_slug: this.currentDeal.slug,
        deal_code: result,  // this may be either just the deal code or the full URL, the API will handle either
        location_id: parseInt(this.locationId)
      }

      this.$store.dispatch("scanClaimDeal", params)
        .then(response => {
          console.log(response);
       
          const path = `/deal/${this.orgSlug}/${this.slug}/l/${this.locationId}/scan-success`
          this.$router.push(path);
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error.message
          }
        })
        .finally(() => {
          this.submitting = false
        })
    },
    logErrors(promise) {
      promise.catch(console.error);
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        switch(error.name) {
          case "StreamApiNotSupportedError":
            this.noStreamApiSupport = true;
            this.error = "Your browser doesn't support necessary camera functionality. Please try a different browser or device."
            break;
          case "NotAllowedError":
            this.error = "Camera access has been denied, but we need it to scan the QR code. Please allow camera acccess and try again."
            break;
          case "NotFoundError":
            this.error = "We couldn't find a camera, but need one to scan the QR code. Please try a different device."
            break;
          case "NotSupportedError":
          case "NotReadableError":
          case "OverconstrainedError":
          default:
              this.error = "We're having trouble accessing your camera. Please try a different browser or device.";
            break;
        }
      } finally {
        this.loading = false
      }
    }
  }
};
</script>

<style scoped>
#QA_qrcode {
  padding: 20px;
}
.loader-wrapper {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}
</style>
